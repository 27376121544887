import FileExplorer from "./fileExplorer";

function index() {
  return (
    <>
      <FileExplorer />
    </>
  );
}

export default index;
